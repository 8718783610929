import React from 'react'

export default ({ title = 'about', text = '', text2 = '', text3 = '' }) => {
  return (
    <div>
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: text }}></p>
      <p dangerouslySetInnerHTML={{ __html: text2 }}></p>
      <p dangerouslySetInnerHTML={{ __html: text3 }}></p>
    </div>
  )
}
