import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import siteConfig from '../../data/siteConfig'
import siteCoverPhoto from '../assets/active.jpg'
import profilePicture from '../assets/profile.jpg'
import loadable from '@loadable/component'
import { Helmet } from 'react-helmet'

import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import About from '../components/about'
import Skills from '../components/skills'
import Timeline from '../components/timeline'
import Projects from '../components/projects'

const Layout = loadable(() => import('../components/layout'))

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.fontColor};
  opacity: 0.2;
`

const Home = ({ className, location }) => {
  // validate siteConfig settings
  if (siteConfig.googleAnalyticsId === 'UA-000000000-1') {
    console.error(
      'WARNING: Please set a proper googleAnalyticsId. See https://analytics.google.com for details.'
    )
  }

  const title1 = siteConfig.headline1
  const title2 = siteConfig.headline2
  const title3 = siteConfig.headline3
  const { keywords } = siteConfig
  return (
    <Layout location={location}>
      <SEO title={siteConfig.siteTitle} keywords={keywords} />
      <Hero
        heroImg={siteCoverPhoto}
        title1={title1}
        title2={title2}
        title3={title3}
      />
      <Wrapper className={className}>
        <Container className="page-content" fluid>
          <Row>
            <Col xs={4} className="avatar">
              <img
                className="avatar__image"
                src={profilePicture}
                alt="user avatar"
              />
              <div className="social">
                {siteConfig.minIntro}{' '}
                <a
                  className={'social-tag__nike'}
                  href={siteConfig.social.nikeTwitter}
                >
                  {siteConfig.nikeTag}
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4}>
              <About
                title="About"
                text={siteConfig.authorDescription}
                text2={siteConfig.authorDescription2}
                text3={siteConfig.authorDescription3}
              />
            </Col>
            <Col xs={4} sm={4}>
              <Skills title="Comfort Zone" skills={siteConfig.skills} />
            </Col>
          </Row>
          <Separator />
          <Timeline />
          <Separator />
          {siteConfig.githubUsername && <Projects />}
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .avatar {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }

  .avatar__image {
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
    max-width: 200px;
    border-radius: 100px;
    margin: 0 auto 24px;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .social-link {
    padding: 8px;
    color: #555;
  }

  a.social-link.twitter:hover {
    color: #1da1f2;
  }

  a.social-link.github:hover {
    color: #24292e;
  }

  a.social-link.linkedin:hover {
    color: #0077b5;
  }

  a.social-link.email:hover {
    color: #c23a2b;
  }

  .social-tag__nike {
    text-decoration: none;
    color: #00acee;
    font-weight: bold;
  }
`
